// eslint-disable-next-line
// @ts-ignore
const windowConfig = window.__LE__ || {}

const defaultLang: string = windowConfig.defaultLang || 'en'
const primaryGenreTagIds = defaultLang === 'en'
  ? [15026, 14396, 14400, 15047, 14392, 14366, 14367, 14372]
  : [32390, 14396, 14400, 14392, 14366, 14367, 14372]
const overviewTagId = defaultLang === 'en'
  ? primaryGenreTagIds[0]
  : 11155 // app tag ID
const apiServer = windowConfig.apiServer || `http://${window.location.hostname}:9000/api`

const config = {
  adminUrl:
    windowConfig.adminUrl || `http://${window.location.hostname}:3000/#`,
  apiPath:           '/app/le',
  apiServer,
  appTagId:          11155,
  articleTplId:      1002,
  assetHost:         'https://cls-assets.imgix.net/',
  assetStore:        'https://cls-assets.s3-eu-central-1.amazonaws.com/',
  authorAgeRange:    60,
  authorTplId:       1001,
  autoSaveSteps:     5,
  connectionTplId:   1009,
  defaultLang,
  defaultPath:       '/',
  featuredTagId:     11159,
  helpTplId:         1010,
  jwtKey:            'lejwt',
  langs:             ['de'],
  localStoreKey:     'le',
  mapboxStyle:       'mapbox://styles/kpvmb/clsvrytbp005m01qub40r3pi2',
  mapboxToken:       'pk.eyJ1Ijoia3B2bWIiLCJhIjoiY2xydGVnYXk0MDEzdDJqczVhOWFoODc4MSJ9.yQDyRhPNuWEsL7KjF6M21A',
  overviewTagId,
  // time in ms that a page has to be visible for it to be considered viewed
  pageViewThreshold: 5 * 1000,
  previewUrl:        '',
  primaryGenreTagIds,
  publicGraphql:     `${apiServer}/app/le/graphql`,
  rootPath:          '/',
  saveDelay:         5000,
  sentryDsn:         windowConfig.sentryDsn,
  sentryEnv:         windowConfig.sentryEnv || 'development',
  timelineLayoutId:  '6',
  useSentry:         windowConfig.useSentry && windowConfig.useSentry === 'true',
  exlAuthorSection:  ['quiz', 'articles', 'read'],
}

export default config
